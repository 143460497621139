import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { FiAlertTriangle } from 'react-icons/fi'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import InputMask from 'react-input-mask'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { DemandStatus } from '../../Enuns/demandStatus.enum'
import { Demand } from '../../Interfaces/demands'
import { Modal } from '../../components/Modal'
import { Pagination } from '../../components/Pagination'
import { SearchContext } from '../../context/SearchContext'
import { api } from '../../services/api'
import { useDemands } from '../../services/hooks/useDemands'
import DemandsCreateOrUpdate from './form'

export function Demands() {
  const { searchValue } = useContext(SearchContext)
  const toast = useToast()

  const [page, setPage] = useState(1)
  const [totalRegisters, setTotalRegisters] = useState(0)
  const [demandId, setDemandId] = useState<number | undefined>(undefined)
  const [serviceOrderId, setServiceOrderId] = useState<number | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(searchValue)

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const [periodStartDate, setPeriodStartDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'dd/MM/yyyy')
  )

  const [periodEndDate, setPeriodEndDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'dd/MM/yyyy')
  )

  const { data, isLoading, isFetching, error, refetch } = useDemands(
    {
      page,
      ...queryContextParams,
      notInStatus: DemandStatus.ENTREGUE,
      demandId,
      serviceOrderId,
      name,
    },
    periodStartDate.toString(),
    periodEndDate.toString()
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()

  const [currentDemand, setCurrentDemand] = useState<Demand>()

  useEffect(() => {
    if (data) {
      setTotalRegisters(data.totalCount)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [queryContextParams, demandId, serviceOrderId, name])

  useEffect(() => {
    if (searchValue) {
      setName(searchValue)
    } else {
      setName(undefined)
    }
  }, [searchValue])

  useEffect(() => {
    return () => {
      clearQuery()
      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentDemand) removeDemand.mutateAsync(currentDemand)
    onClose()
  }

  const removeDemand = useMutation(
    async (demand: Demand) => {
      const response = await api.delete(`demands/${demand.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
      }

      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao remover pedido', status: 'error' })
        }
      },
    }
  )

  const handleModalOpen = (demand = null) => {
    setCurrentDemand({} as Demand)
    if (!demand) {
      onCreateOpen()
    } else {
      setCurrentDemand(demand)
      demand.deleted ? onOpen() : onCreateOpen()
    }
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal" marginRight="2rem">
          Pedidos
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>

        <Heading size="md" fontWeight="normal">
          Período inicial:{' '}
          <Input
            label="Período Inicial"
            as={InputMask}
            defaultValue={periodStartDate}
            onChange={(e) => setPeriodStartDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>
        <Heading size="md" fontWeight="normal" marginLeft="1rem" marginRight="1rem">
          Período final:{' '}
          <Input
            label="Período final"
            as={InputMask}
            defaultValue={periodEndDate}
            onChange={(e) => setPeriodEndDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>
        <Heading size="md" fontWeight="normal" marginLeft="1rem" marginRight="1rem">
          Id do Pedido:{' '}
        <Input
          value={demandId || ''}
          onChange={(e) => setDemandId(e.target.value ? parseInt(e.target.value) : undefined)}
          width="20vh"
          mr="4"
        />
        </Heading>
        <Heading size="md" fontWeight="normal" marginLeft="1rem" >
          Id da Ordem de Serviço:{' '}
        <Input
          value={serviceOrderId || ''}
          onChange={(e) => setServiceOrderId(e.target.value ? parseInt(e.target.value) : undefined)}
          width="20vh"
        />
        </Heading>

        <Heading size="md" fontWeight="normal" marginLeft="1rem">
        <Button
          onClick={() => handleModalOpen()}
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
          Criar Novo
        </Button>
        </Heading>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter os fornecedores</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>ID</Th>
              <Th>Pedido</Th>
              <Th>Status</Th>
              <Th>Data</Th>
              <Th width="4" />
              <Th width="4" />
            </Tr>
          </Thead>
          <Tbody>
            {data?.demands.map((demand: Demand) => {
              return (
                <Tr key={demand.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Text fontWeight="bold">{demand.id}</Text>
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{demand.client.company_name}</Text>
                      <Text fontSize="small" color="gray.300">
                        {demand.client.resp_name}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    {(() => {
                      switch (demand.status) {
                        case DemandStatus.EM_PROGRESSO:
                          return (
                            <Tag size="md" variant="solid" colorScheme="cyan">
                              EM PROGRESSO
                            </Tag>
                          )
                        case DemandStatus.ENTREGUE:
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              ENTREGUE
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              NOVO
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>{format(parseISO(demand.created_at), 'dd/MM/yyyy')}</Td>
                  <Td display="grid" gridGap="2" gridTemplateColumns="1fr 1fr 1fr">
                    <Tooltip label="Editar" placement="top">
                      <IconButton
                        onClick={() => handleModalOpen(demand)}
                        size="md"
                        fontSize="sm"
                        colorScheme="purple"
                        aria-label="Editar"
                        icon={<Icon as={RiPencilLine} />}
                      />
                    </Tooltip>
                    <Tooltip label="Remover" placement="top">
                      <IconButton
                        onClick={() => handleModalOpen({ ...demand, deleted: true })}
                        size="md"
                        fontSize="sm"
                        colorScheme="red"
                        aria-label="Remover"
                        icon={<Icon as={RiDeleteBin6Line} />}
                      />
                    </Tooltip>
                    <Tooltip label="Adicionar Ordem" placement="top">
                      <IconButton
                        as={Link}
                        to={`/demands/${demand.id}/services_order`}
                        size="md"
                        fontSize="sm"
                        colorScheme="facebook"
                        aria-label="Adicionar Ordem"
                        icon={<Icon as={AiOutlineUnorderedList} />}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Pagination
        totalCountOfRegisters={totalRegisters}
        currentPage={page}
        onPageChange={setPage}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Remover Pedido"
        buttonTitle="Remover"
        buttonAction={handleRemoveButton}>
        <Text mb="5" fontWeight="bold">
          <Icon color="pink.A400" mr="2" as={FiAlertTriangle} fontSize="20" />
          Atênção, ao remover o pedidos, todas as ordens serão deletadas
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Cliente: ${currentDemand?.client?.company_name}`}
          </ListItem>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Resposável: ${currentDemand?.client?.resp_name}`}
          </ListItem>
        </List>
      </Modal>

      <Modal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        title={currentDemand?.id ? 'Editar Pedido' : 'Novo Pedido'}
        buttonTitle="Adicionar"
        size="xl"
        buttonAction={handleRemoveButton}
        hasFooter={false}>
        <DemandsCreateOrUpdate currendDemand={currentDemand} onClose={onCreateClose} />
      </Modal>
    </Box>
  )
}