export const ovenSapace = [
  {
    label: '1/3',
    value: 1,
  },
  {
    label: '2/3',
    value: 2,
  },
  {
    label: '3/3',
    value: 3,
  },
]
