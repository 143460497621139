import { Flex, Button, Stack, Grid, Box, Link } from '@chakra-ui/react'
import { Input } from '../components/Form/Input'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldError } from 'react-hook-form'
import { useContext } from 'react'
import { AuthContext, cookies } from '../context/AuthContext'
import { useEffect } from 'react'

export default function SignIn() {
  const history = useHistory()

  const { signIn } = useContext(AuthContext)

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        username: yup.string().required('Campo Nome de usuário obrigatório'),
        password: yup.string().required('Campo senha obrigatório'),
      }),
    ),
  })

  const errors = formState.errors

  useEffect(() => {
    if (!!cookies.get('@AXAuth:Token')) {
      history.push('/raw-materials')
    }
  }, [])

  const handleSignIn = async (data) => {
    if (await signIn(data)) history.push('/raw-materials')
  }

  return (
    <Flex h="100vh" w="100vw" justifyContent="center" alignItems="center" verticalAlign="middle">
      <Box borderRadius="8" bg="gray.800" p="8" w="25rem">
        <Grid gridTemplateColumns="1fr">
          <Flex as="form" flexDir="column" onSubmit={handleSubmit(handleSignIn)}>
            <Stack>
              <Input label="Nome de usuário" {...register('username')} error={errors.username as FieldError} />
              <Input type="password" label="Senha" {...register('password')} error={errors.password as FieldError} />
            </Stack>

            <Button type="submit" mt="6" colorScheme="pink" size="lg">
              Entrar
            </Button>

            <Box textAlign="center" padding="1rem" color="pink.500" fontSize="sm">
              <Link as="button" onClick={() => history.push('/forgot-password')}>
                <strong>Esqueci minha senha</strong>
              </Link>
            </Box>
          </Flex>
        </Grid>
      </Box>
    </Flex>
  )
}
