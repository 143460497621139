import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Input } from '../../../../../components/Form/Input'
import { RawMaterial } from '../../../../../Interfaces/rawMaterial'
import { Product } from '../../../../../Interfaces/products'
import { useEffect } from 'react'
import { WorkSchedule } from '../../../../../Interfaces/workSchedule'
import { Step } from '../../../../../Enuns/step.enum'
import { Select } from '../../../../../components/Form/Select'
import { ServiceRawMaterials } from '../../../../../Interfaces/serviceRawMaterials'

const createServiceOrderSchema = yup.object().shape({
  raw_material: yup.string(),
  spent_amount: yup.string().required('Informe a quantidade'),
})

interface Props {
  selectedRawMaterial?: ServiceRawMaterials
  orderId: string
  onClose: () => void
  step: Step
}

export default function RawMaterialServiceCreateOrUpdate({ selectedRawMaterial, onClose, orderId, step }: Props) {
  const queryClient = useQueryClient()
  const toast = useToast()

  const id = selectedRawMaterial?.id ?? ''

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createServiceOrderSchema),
  })

  const errors = formState.errors

  const isCreateMode = !id

  const createDemand = useMutation(
    async (rawMaterial: any) => {
      const workSchedule = {
        spent_amount: rawMaterial.spent_amount,
        step,
      }

      const response = await api.post<Product>(`/service-orders/${orderId}/raw-materials/${rawMaterial.raw_material}`, {
        ...workSchedule,
      })

      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })

        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('serviceOrderProduct')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          if (response.data.error) {
            toast({ description: response.data.error.developer_message, status: 'error' })
          } else {
            toast({ description: response.data.message, status: 'error' })
          }
        }
      },
    },
  )

  useEffect(() => {
    if (id) {
      reset({ ...selectedRawMaterial })
    }
  }, [])

  const updateDemands = useMutation(
    async (rawMaterial: any) => {
      const workSchedule = {
        spent_amount: rawMaterial.spent_amount,
        step,
      }

      const response = await api.put<Product>(`/service-orders/${orderId}/raw-materials/${selectedRawMaterial.id}`, {
        ...workSchedule,
      })

      if (response) {
        toast({ description: 'Editado com sucesso.' })

        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('serviceOrderProduct')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          if (response.data.error) {
            toast({ description: response.data.error.developer_message, status: 'error' })
          } else {
            toast({ description: response.data.message, status: 'error' })
          }
        }
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<WorkSchedule> = async (values) => {
    isCreateMode ? await createDemand.mutateAsync(values) : await updateDemands.mutateAsync(values)
  }

  const { data: rawMaterials } = useQuery(
    'rawMaterials',
    async () => {
      const response = await api.get<RawMaterial[]>(`raw-materials`)

      // Primeiro, ordena os dados recebidos pela propriedade desejada (ex.: 'name')
      const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name))

      // Depois, mapeia os dados ordenados para o formato esperado pelo Select
      const selectOptions = sortedData.map((x) => ({
        label: `${x.name} - ${x.amount} ${x.measurement_unit}`,
        value: x.id,
      }))

      return { rawMaterials: selectOptions }
    },
    { retry: false },
  )

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          {!id && (
            <Select
              label="Selecione uma opção:"
              {...register('raw_material')}
              options={rawMaterials?.rawMaterials}
              placeholder="Matéria-prima"
            />
          )}

          <Input label="Quantidade" {...register('spent_amount')} error={errors.spent_amount as FieldError} />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
