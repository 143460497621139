import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../../services/api'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { Select } from '../../../../components/Form/Select'
import { Input } from '../../../../components/Form/Input'
import { accessOptions } from '../../../../utils/billingForms'
import { RawMaterial } from '../../../../Interfaces/rawMaterial'
import { ovenSapace } from '../../../../utils/ovenSapace'
import InputMask from 'react-input-mask'
import { format } from 'date-fns'
import { Product } from '../../../../Interfaces/products'
import { useEffect } from 'react'
import { stepOptions } from '../../../../utils/serviceOrderSteps'

const createServiceOrderSchema = yup.object().shape({
  delivery_date: yup.string().required('Informe a data de entrega'),
  description: yup.string().required('Informe o nome do produto'),
  amount: yup.string().required('Informe a quantidade'),
  ink_color_id: yup.string().required('Informe a cor'),
  price: yup.string().required('Informe o preço'),
  billing_method: yup.string().required('Informe forma de cobrança'),
  oven_space: yup.number().required('Informe queimada'),
})

interface Props {
  selectedProduct?: Product
  orderId: string
  onClose: () => void
}

export default function ProdcutsCreateOrUpdate({ selectedProduct, onClose, orderId }: Props) {
  const queryClient = useQueryClient()
  const toast = useToast()

  const id = selectedProduct?.id ?? ''

  const { register, handleSubmit, formState, reset, getValues } = useForm({
    resolver: yupResolver(createServiceOrderSchema),
  })

  const errors = formState.errors

  const { data: colors } = useQuery(
    'colors',
    async () => {
      const response = await api.get<RawMaterial[]>(`raw-materials`, {
        params: {
          category: 'TINTASCOLORIDAS',
        },
      })

      const select = response.data.map((x: RawMaterial) => ({
        label: `${x.name} - ${x.amount}KG`,
        value: x.id,
      }))

      return {
        colors: select,
      }
    },
    {
      retry: false,
    },
  )

  const isCreateMode = !id

  const createDemand = useMutation(
    async (product: Product) => {
      const date = product.delivery_date.split('/')

      const products = {
        ...product,
        delivery_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        service_order_id: orderId,
      }

      const response = await api.post<Product>(`products`, {
        ...products,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.', status: 'success' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('serviceOrderProduct')
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  useEffect(() => {
    if (id) {
      reset({ ...selectedProduct, delivery_date: format(new Date(selectedProduct.delivery_date), 'dd/MM/yyyy') })
    }
  }, [])

  const updateDemands = useMutation(
    async (product: Product) => {
      const date = product.delivery_date.split('/')

      const products = {
        ...product,
        delivery_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        service_order_id: orderId,
      }

      const response = await api.put<Product>(`products/${id}`, { ...products })

      if (response) {
        toast({ description: 'Editado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('demands')
        queryClient.invalidateQueries('serviceOrderProduct')
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<Product> = async (values) => {
    isCreateMode ? await createDemand.mutateAsync(values) : await updateDemands.mutateAsync(values)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Data"
            {...register('delivery_date')}
            error={errors.delivery_date as FieldError}
            as={InputMask}
            mask="99/99/9999"
          />
          <Input label="Nome do produto" {...register('description')} error={errors.description as FieldError} />
          <Input label="Quantidade" {...register('amount')} error={errors.amount as FieldError} />
          <Select label="Cores" {...register('ink_color_id')} options={colors?.colors} placeholder="Selecione um cor" />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Valor" {...register('price')} error={errors.price as FieldError} />
          <Select
            label="Forma de cobrança"
            {...register('billing_method')}
            options={accessOptions}
            placeholder="Selecione"
          />

          <Select label="Queimada" {...register('oven_space')} options={ovenSapace} placeholder="Selecione" />
          <Select label="Etapa" {...register('step')} options={stepOptions} placeholder="Selecione" />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
