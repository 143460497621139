/* eslint-disable react/jsx-props-no-spreading */
import { Route, RouteProps, useHistory } from 'react-router-dom'
import { cookies } from '../context/AuthContext'
import { Auth } from '../pages/_layouts/auth'
import { Default } from '../pages/_layouts/default'

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any
}

export default function RouteWrapper(props: PrivateRouteProps) {
  const history = useHistory()

  const { component: Component, ...rest } = props

  const token = cookies.get('@AXAuth:Token')

  const Layout = Default

  // if (!token && !['/', '/forgot-password', '/token-password'].includes(props.path as string)) {
  //   history.push('/');
  // }
  return (
    <Route
      {...rest}
      render={(p) => (
        <Layout openLayout={token ? true : false}>
          <Component {...p} />
        </Layout>
      )}
    />
  )
}
