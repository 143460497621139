import axios, { AxiosError } from 'axios';
import { cookies } from '../context/AuthContext';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: !!cookies && cookies ? `Bearer ${cookies.get('@AXAuth:Token')}` : '',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError) => {
    if (err.response.statusText === 'Unauthorized') {
      try {
        await api.post('/logout');
      } catch (err) {
        window.location.href = '/';
      }

      cookies.remove('@AXAuth:Token', { path: '/' });

      cookies.remove('@AXAuth:User', { path: '/' });

      window.location.href = '/';
    }

    return Promise.reject(err);
  },
);
