import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Input } from '../../../components/Form/Input'
import { api } from '../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Select } from '../../../components/Form/Select'
import InputMask from 'react-input-mask'
import { Costs } from '../../../Interfaces/costs'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'

const criarDespesasSchema = yup.object().shape({
  description: yup.string().required('Nome da despesa é obrigatório'),
  type: yup.string().required('Tipo da despesa é obrigatório'),
  category: yup.string().required('Categoria da despesa é obrigatório'),
  value: yup.string().required('Valor da despesa é obrigatório'),
  payment_status: yup.string().required('Situação do pagamento é obrigatório'),
  payment_date: yup.string(),
})

interface Props {
  costs?: Costs
  onClose: () => void
}

export default function CostsCreateOrUpdate({ costs, onClose }: Props) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [paymentStatusValid, setPaymentStatusValid] = useState(false)

  const situacaoDespesaOpcoes = [
    {
      label: 'Pago',
      value: 'PAGO',
    },
    {
      label: 'Pendente',
      value: 'PENDENTE',
    },
  ]

  const despesaOpcoes = [
    {
      label: 'Fixo',
      value: 'FIXO',
    },
    {
      label: 'Variável',
      value: 'VARIAVEL',
    },
  ]

  const categoriasDespesa = [
    {
      label: 'Empresa',
      value: 'EMPRESA',
    },
    {
      label: 'Pessoal',
      value: 'PESSOAL',
    },
    {
      label: 'Outros',
      value: 'OUTROS',
    },
  ]

  const id = costs?.id ?? ''

  const isCreateMode = !id

  const criarDespesas = useMutation(
    async (cost: Costs) => {
      const date = paymentStatusValid ? cost.payment_date.split('/') : null

      const response = await api.post('costs', {
        ...cost,
        payment_date:
          cost.payment_status === 'PAGO' ? format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd') : null,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('costs')
      },
      onError: ({ response }) => {
        const errorList = response && response.data.errors
        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        }
      },
    },
  )

  const updateUsers = useMutation(
    async (cost: Costs) => {
      const date = paymentStatusValid ? cost.payment_date.split('/') : null
      const response = await api.put(`costs/${id}`, {
        ...cost,
        payment_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
      })
      if (response) {
        toast({ description: 'Editado com sucesso.' })
        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('costs')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset, getValues, watch } = useForm({
    resolver: yupResolver(criarDespesasSchema),
  })

  const payment_status = watch('payment_status')

  const errors = formState.errors

  const handleCreateOrUpdate: SubmitHandler<Costs> = async (values) => {
    isCreateMode ? await criarDespesas.mutateAsync(values) : await updateUsers.mutateAsync(values)

    history.push('/costs')
  }

  useEffect(() => {
    if (id) {
      reset({ ...costs, payment_date: costs.payment_date ? format(new Date(costs.payment_date), 'dd/MM/yyyy') : '' })
    }
  }, [])

  useEffect(() => {
    console.log(getValues())
  }, [getValues()])

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome" {...register('description')} error={errors.description as FieldError} />
          <Select
            label="Situação do pagamento"
            {...register('payment_status')}
            onChange={(e) => (e.target.value === 'PAGO' ? setPaymentStatusValid(true) : setPaymentStatusValid(false))}
            options={situacaoDespesaOpcoes}
            placeholder="Situação do pagamento"
          />
          <Select label="Tipo da despesa" {...register('type')} options={despesaOpcoes} placeholder="Tipo da despesa" />
          <Select
            label="Categoria da despesa"
            {...register('category')}
            options={categoriasDespesa}
            placeholder="Categoria"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Valor" {...register('value')} error={errors.value as FieldError} />
          {paymentStatusValid ? (
            <Input
              label="Data do pagamento"
              {...register('payment_date')}
              error={errors.payment_date as FieldError}
              as={InputMask}
              defaultValue={!id && String(format(new Date(), 'dd/MM/yyyy'))}
              mask="99/99/9999"
            />
          ) : (
            ''
          )}
          <small> (Deve ser cadastradada com o mês atual para ser listada)</small>
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as="a" onClick={() => onClose()} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
