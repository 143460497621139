import { Flex, Button, useToast, Box, Heading, Divider, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Input } from '../../../components/Form/Input'
import { api } from '../../../services/api'
import { Provider } from '../../../Interfaces/provider'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Select } from '../../../components/Form/Select'
import { states } from '../../../utils/states'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'

const createProviderSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  cnpj: yup.string().required('CNPJ obrigatório'),
  phone: yup.string().required('Telefone obrigatório'),
  email: yup.string().required('E-mail obrigatório'),
  address: yup.object().shape({
    zip_code: yup.string().required('CEP obrigatório'),
    state: yup.string().required('Estado obrigatório'),
    city: yup.string().required('Cidade obrigatória'),
    neighborhood: yup.string().required('Bairro obrigatório'),
    complement: yup.string().required('Endereço obrigatório'),
  }),
})

export default function ProviderCreateOrUpdate({ match }: any) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { id } = match.params

  const isCreateMode = !id

  const createProvider = useMutation(
    async (provider: Provider) => {
      const responseAddress = await api.post('addresses', {
        zip_code: provider.address.zip_code,
        state: provider.address.state,
        city: provider.address.city,
        neighborhood: provider.address.neighborhood,
        complement: provider.address.complement,
      })
      if (responseAddress) {
        provider.address_id = responseAddress.data.id
        const responseProvider = await api.post('providers', {
          address_id: provider.address_id,
          name: provider.name,
          cnpj: provider.cnpj,
          email: provider.email,
          phone: provider.phone,
        })
        if (responseProvider) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('providers')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateProviders = useMutation(
    async (provider: Provider) => {
      const responseAddress = await api.put(`addresses/${provider.address_id}`, {
        zip_code: provider.address.zip_code,
        state: provider.address.state,
        city: provider.address.city,
        neighborhood: provider.address.neighborhood,
        complement: provider.address.complement,
      })
      if (responseAddress) {
        provider.address_id = responseAddress.data.id
        const responseProvider = await api.put(`providers/${id}`, {
          address_id: provider.address_id,
          name: provider.name,
          cnpj: provider.cnpj,
          email: provider.email,
          phone: provider.phone,
        })
        if (responseProvider) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('raw-materials')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createProviderSchema),
  })

  useQuery(
    'provider',
    async () => {
      const { data } = await api.get(`providers/${id}`)
      reset(data)
    },
    {
      staleTime: 5000,
    },
  )

  const errors = formState.errors

  const handleCreateProvider: SubmitHandler<Provider> = async (values) => {
    isCreateMode ? await createProvider.mutateAsync(values) : await updateProviders.mutateAsync(values)

    history.push('/providers')
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateProvider)}>
      <Heading size="lg" fontWeight="normal">
        {isCreateMode ? 'Novo Fornecedor' : 'Editar Fornecedor'}
      </Heading>
      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome" {...register('name')} error={errors.name as FieldError} />
          <Input
            label="CNPJ"
            {...register('cnpj')}
            error={errors.cnpj as FieldError}
            as={InputMask}
            mask="99.999.999/9999-99"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Telefone" {...register('phone')} error={errors.phone as FieldError} />
          <Input label="E-mail" {...register('email')} error={errors.email as FieldError} />
        </SimpleGrid>

        <Heading size="md" fontWeight="normal">
          Endereço
        </Heading>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="CEP"
            {...register('address.zip_code')}
            error={(errors.address as { zip_code?: FieldError })?.zip_code || undefined}
            as={InputMask}
            mask="99999-999"
          />
          <Input
            label="Cidade"
            {...register('address.city')}
            error={(errors.address as { city?: FieldError })?.city || undefined}
          />
          <Select label="Estado" {...register('address.state')} options={states} placeholder="Estado" />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Endereço"
            {...register('address.complement')}
            error={(errors.address as { complement?: FieldError })?.complement || undefined}
          />
          <Input
            label="Bairro"
            {...register('address.neighborhood')}
            error={(errors.address as { neighborhood?: FieldError })?.neighborhood || undefined}
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to={`/providers`} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
