import { Flex, Button, useToast, Box, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Input } from '../../../../../components/Form/Input'
import { RawMaterial } from '../../../../../Interfaces/rawMaterial'
import InputMask from 'react-input-mask'
import { format } from 'date-fns'
import { Product } from '../../../../../Interfaces/products'
import { useEffect } from 'react'
import { WorkSchedule } from '../../../../../Interfaces/workSchedule'
import { Step } from '../../../../../Enuns/step.enum'

const createServiceOrderSchema = yup.object().shape({
  work_date: yup.string().required('Informe a data'),
  start_work: yup.string().required('Informe a hora de início'),
  end_work: yup.string().required('Informe a hora de termino'),
})

interface Props {
  selectedProduct?: WorkSchedule
  orderId: string
  onClose: () => void
  step: Step
}

export default function WorkScheduleCreateOrUpdate({ selectedProduct, onClose, orderId, step }: Props) {
  const queryClient = useQueryClient()
  const toast = useToast()

  const id = selectedProduct?.id ?? ''

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createServiceOrderSchema),
  })

  const errors = formState.errors

  const { data: colors } = useQuery(
    'colors',
    async () => {
      const response = await api.get<RawMaterial[]>(`raw-materials`, {
        params: {
          category: 'TINTASCOLORIDAS',
        },
      })

      const select = response.data.map((x: RawMaterial) => ({
        label: `${x.name} - ${x.amount}KG`,
        value: x.id,
      }))

      return {
        colors: select,
      }
    },
    {
      retry: false,
    },
  )

  const isCreateMode = !id

  const createDemand = useMutation(
    async (product: WorkSchedule) => {
      const date = product.work_date.split('/')

      const workSchedule = {
        ...product,
        work_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        step,
      }

      const response = await api.post<Product>(`/service-orders/${orderId}/work-schedules`, {
        ...workSchedule,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })

        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workSchedule')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  useEffect(() => {
    if (id) {
      reset({ ...selectedProduct, work_date: format(new Date(selectedProduct.work_date), 'dd/MM/yyyy') })
    }
  }, [])

  const updateDemands = useMutation(
    async (product: WorkSchedule) => {
      const date = product.work_date.split('/')

      const workSchedule = {
        ...product,
        work_date: format(new Date(`${date[2]}/${date[1]}/${date[0]}`), 'yyyy-MM-dd'),
        step,
      }

      const response = await api.put<Product>(`/service-orders/${orderId}/work-schedules/${id}`, {
        ...workSchedule,
      })
      if (response) {
        toast({ description: 'Cadastrado com sucesso.' })

        onClose()
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workSchedule')
        queryClient.refetchQueries()
      },
      onError: ({ response }) => {
        const errorList = response?.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: 'Falha ao cadastrar', status: 'error' })
        }
      },
    },
  )

  const handleCreateOrUpdate: SubmitHandler<WorkSchedule> = async (values) => {
    isCreateMode ? await createDemand.mutateAsync(values) : await updateDemands.mutateAsync(values)
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" as="form" onSubmit={handleSubmit(handleCreateOrUpdate)}>
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Data"
            {...register('work_date')}
            error={errors.work_date as FieldError}
            as={InputMask}
            mask="99/99/9999"
          />
          <Input
            label="Hr. Início"
            {...register('start_work')}
            error={errors.start_work as FieldError}
            as={InputMask}
            mask="99:99:00"
          />
          <Input
            label="Hr. Final"
            {...register('end_work')}
            error={errors.end_work as FieldError}
            as={InputMask}
            mask="99:99:00"
          />
        </SimpleGrid>
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
