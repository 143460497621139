import {
  Box,
  Button,
  Checkbox,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Spinner,
  useToast,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  Tag,
  Input,
} from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import { RiAddLine, RiCheckLine, RiDeleteBin6Line, RiPencilLine } from 'react-icons/ri'
import { useMutation } from 'react-query'
import { Modal } from '../../components/Modal'
import { api } from '../../services/api'
import { useCosts } from '../../services/hooks/useCosts'
import { SearchContext } from '../../context/SearchContext'
import { add, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Costs } from '../../Interfaces/costs'
import CurrencyFormat from 'react-currency-format'
import CostsCreateOrUpdate from './form'
import InputMask from 'react-input-mask'

export function MonthCosts() {
  const toast = useToast()

  const [currentCost, setCurrentCost] = useState<Costs>()

  const { queryContextParams, clearQuery } = useContext(SearchContext)

  const [periodStartDate, setPeriodStartDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'dd/MM/yyyy'),
  )

  const [periodEndDate, setPeriodEndDate] = useState(
    format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'dd/MM/yyyy'),
  )

  const { data, isLoading, isFetching, error, refetch } = useCosts(periodStartDate.toString(), periodEndDate.toString())

  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    refetch()
  }, [queryContextParams])

  useEffect(() => {
    return () => {
      clearQuery()

      refetch()
    }
  }, [])

  const handleRemoveButton = () => {
    if (currentCost) removeUser.mutateAsync(currentCost)

    onClose()
  }

  const removeUser = useMutation(
    async (costs: Costs) => {
      const response = await api.delete(`costs/${costs.id}`)

      if (response) {
        toast({ description: 'Removido com sucesso', status: 'success' })
        refetch()
      }

      onClose()
      return response.data
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const handleModalOpen = (cost = null) => {
    setCurrentCost({} as Costs)
    if (!cost) {
      onCreateOpen()
    } else {
      setCurrentCost(cost)
      cost.deleted ? onOpen() : onCreateOpen()
    }
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8">
      <Flex mb="8" justifyContent="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Despesas -{' '}
          <Tag fontSize="30">
            {format(new Date(), 'MMMM', {
              locale: ptBR,
            }).toLocaleUpperCase()}
          </Tag>
          {!isLoading && isFetching && <Spinner size="sm" color="gray.500" ml="4" />}
        </Heading>

        <Heading size="md" fontWeight="normal">
          Período inicial:{' '}
          <Input
            label="Período Inicial"
            as={InputMask}
            defaultValue={periodStartDate}
            onChange={(e) => setPeriodStartDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>
        <Heading size="md" fontWeight="normal" marginLeft="1rem" marginRight="1rem">
          Período final:{' '}
          <Input
            label="Período final"
            as={InputMask}
            defaultValue={periodEndDate}
            onChange={(e) => setPeriodEndDate(e.target.value)}
            mask="99/99/9999"
            width="15vh"
          />
        </Heading>

        <Button
          onClick={() => handleModalOpen()}
          size="sm"
          fontSize="sm"
          colorScheme="pink"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
          Criar Novo
        </Button>
      </Flex>

      {isLoading ? (
        <Flex justify="center">
          <Spinner />
        </Flex>
      ) : error ? (
        <Flex justify="center">
          <Text>Falha ao obter as despesas</Text>
        </Flex>
      ) : (
        <Table colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="pink" />
              </Th>
              <Th>OS</Th>
              <Th>Nome da despesa</Th>
              <Th>Tipo</Th>
              <Th>Categoria</Th>
              <Th>Status pagamento</Th>
              <Th>Valor</Th>
              <Th width="4" />
              <Th width="4" />
            </Tr>
          </Thead>
          <Tbody>
            {data?.costs.map((costs: Costs) => {
              return (
                <Tr key={costs.id}>
                  <Td px={['4', '4', '6']}>
                    <Checkbox colorScheme="pink" />
                  </Td>
                  <Td>
                    <Text fontWeight="bold">{costs.id_os}</Text>
                  </Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold">{costs.description}</Text>
                      <Text fontSize="small" color="gray.300">
                        {costs.payment_date &&
                          format(
                            add(new Date(costs.payment_date), {
                              hours: 4,
                            }),
                            'dd/MM/yyyy',
                          )}
                      </Text>
                    </Box>
                  </Td>

                  <Td>
                    {(() => {
                      switch (costs.type) {
                        case 'VARIAVEL':
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              VARIÁVEL
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              FIXO
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    {(() => {
                      switch (costs.category) {
                        case 'PESSOAL':
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              PESSOAL
                            </Tag>
                          )
                        case 'OUTROS':
                          return (
                            <Tag size="md" variant="solid" colorScheme="blue">
                              OUTROS
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              EMPRESA
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    {(() => {
                      switch (costs.payment_status) {
                        case 'PENDENTE':
                          return (
                            <Tag size="md" variant="solid" colorScheme="red">
                              PENDENTE
                            </Tag>
                          )
                        default:
                          return (
                            <Tag size="md" variant="solid" colorScheme="green">
                              PAGO
                            </Tag>
                          )
                      }
                    })()}
                  </Td>
                  <Td>
                    R${' '}
                    <CurrencyFormat displayType="text" decimalSeparator="." thousandSeparator="," value={costs.value} />
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen(costs)}
                      size="sm"
                      fontSize="sm"
                      colorScheme="purple"
                      leftIcon={<Icon as={RiPencilLine} />}>
                      Editar
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handleModalOpen({ ...costs, deleted: true })}
                      size="sm"
                      disabled={costs.id_os !== null && costs.id_os !== undefined}
                      fontSize="sm"
                      colorScheme="red"
                      leftIcon={<Icon as={RiDeleteBin6Line} />}>
                      Remover
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}

      <Modal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        title={currentCost?.id ? 'Editar Despesa' : 'Nova Despesa'}
        buttonTitle="Adicionar"
        size="xl"
        buttonAction={handleRemoveButton}
        hasFooter={false}>
        <CostsCreateOrUpdate costs={currentCost} onClose={onCreateClose} />
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Remover Despesa"
        buttonTitle="Remover"
        buttonAction={handleRemoveButton}>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={RiCheckLine} color="green.500" />
            {`Nome: ${currentCost?.description}`}
          </ListItem>
        </List>
      </Modal>
    </Box>
  )
}
