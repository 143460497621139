import { Flex, Button, Stack, Grid, Box, Text, useToast } from '@chakra-ui/react'
import { Input } from '../../../components/Form/Input'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, useForm } from 'react-hook-form'
import { cookies } from '../../../context/AuthContext'
import { api } from '../../../services/api'
import { useMutation } from 'react-query'

export default function ForgotPassword() {
  const history = useHistory()

  const toast = useToast()

  const {
    register: register,
    handleSubmit: handleSubmit,
    formState: formState,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email('Necessário email válido').required('Campo email obrigatório'),
      }),
    ),
  })

  const errors = formState.errors

  const sendMailRecovery = useMutation(
    async (data: string) => {
      const response = await api.post('resetpassword/sendmail', {
        email: data,
        redirectUrl: process.env.REACT_APP_URL,
      })
      if (response) {
        toast({ description: 'Um token de acesso foi enviado. Cheque seu email.', status: 'success' })
        history.push('/')
      }
    },
    {
      onError: ({ response }) => {
        const errorList = response.data.errors

        if (errorList) {
          const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
          toastOptions.forEach((t) => {
            toast(t)
          })
        } else {
          toast({ description: response.data.error, status: 'error' })
        }
      },
    },
  )

  const handleForgotPass = (values) => {
    cookies.remove('@AXAuth:Token', { path: '/' })

    sendMailRecovery.mutateAsync(values)
  }

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center" verticalAlign="middle">
      <Box borderRadius="8" bg="gray.800" p="8" w="25rem">
        <Grid gridTemplateColumns="1fr">
          <Flex as="form" flexDir="column" onSubmit={handleSubmit(handleForgotPass)}>
            <Stack paddingBottom="1rem">
              <Text fontSize="xl">
                Para recuperar seu acesso, informe o email de sua conta e receba um token de acesso
              </Text>
            </Stack>
            <Stack paddingTop="1rem">
              <Input label="" placeholder="Email" {...register('email')} error={errors.email as FieldError} />
            </Stack>

            <Flex flexDir="row" justifyContent="space-between">
              <Button type="button" mt="6" colorScheme="linkedin" size="lg" onClick={() => history.push('/')}>
                Voltar
              </Button>
              <Button type="submit" mt="6" colorScheme="pink" size="lg">
                Enviar
              </Button>
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </Flex>
  )
}
