import { useQuery } from 'react-query'
import { api } from '../api'
import { Recipes } from '../../Interfaces/recipes'

type GetRecipesResponse = {
  recipes: Recipes[]
}

export async function getRecipes(start_date: string, end_date: string): Promise<GetRecipesResponse> {
  const { data } = await api.get('recipes', {
    params: {
      start_date,
      end_date,
    },
  })

  return {
    recipes: data,
  }
}

export function useRecipes(start_date: string, end_date: string) {
  return useQuery(['recipes', start_date, end_date], () => getRecipes(start_date, end_date), {
    staleTime: 5000,
  })
}
