import { Box, Button, Divider, HStack, Icon, useBreakpointValue, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { RiNotificationLine, RiSearchLine, RiAlertFill } from 'react-icons/ri';
import { ConfigurationContext } from '../../context/ConfigurationContext';
import { SearchContext } from '../../context/SearchContext';
import { Notifications } from '../../Interfaces/notifications';

export function NotificationsNav() {
  const { isShortScreenOpen, setIsShortScreenOpen } = useContext(SearchContext);
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const openSearch = () => {
    setIsShortScreenOpen(!isShortScreenOpen);
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (notificationRef.current && !notificationRef.current.contains(e.target)) {
        setShowNotification(false);
      }
    });
  }, []);

  const { notifications }: { notifications: Notifications[] } = useContext(ConfigurationContext);

  return (
    <HStack
      spacing={['6', '8']}
      mx={['6', '8']}
      pr={['6', '8']}
      py="1"
      color="gray.300"
      borderRightWidth={1}
      borderColor="gray.700"
    >
      {!isWideVersion && (
        <Box as="button" onClick={openSearch}>
          <Icon _hover={{ color: 'pink.A400' }} as={RiSearchLine} fontSize="20" />
        </Box>
      )}
      <Button
        position="relative"
        minWidth="auto"
        variant="unstyled"
        onClick={() => setShowNotification(!showNotification)}
        ref={notificationRef}
      >
        <Icon as={RiNotificationLine} fontSize="20" />
        {notifications.length > 0 && (
          <Box
            position="absolute"
            top="4px"
            right="-6px"
            backgroundColor="red.400"
            color="white"
            width="15px"
            height="15px"
            borderRadius="15px"
            fontSize="12px"
            textAlign="center"
            justifyContent="center"
            lineHeight="15px"
            minWidth="auto"
            // Remove the variant="unstyled" prop
          >
            {notifications.length}
          </Box>
        )}
        <Box
          backgroundColor="purple.500"
          position={'absolute'}
          marginTop={'20px'}
          zIndex={9}
          display={showNotification ? 'initial' : 'none'}
        >
          <Box p="10px" borderBottom="1px" borderColor="white" color="white" backgroundColor="pink.400">
            <Icon as={RiAlertFill} /> Estoque baixo
          </Box>
          <VStack spacing="0">
            {notifications.map((notification) => {
              return (
                <>
                  <Box
                    key={notification.id}
                    as={Link}
                    to={`/raw-materials/edit/${notification.id}`}
                    width="100%"
                    display="grid"
                    mt="0"
                    color="white"
                    gridTemplateColumns="1fr 100px"
                    _hover={{ backgroundColor: 'pink.A400' }}
                  >
                    <Box fontSize="14px" px="10px" py="10px">
                      <span>{notification.name}</span>
                    </Box>
                    <Box borderLeft="1px" fontSize="14px" px="10px" py="10px">
                      {notification.amount} - {notification.measurement_unit}
                    </Box>
                  </Box>
                  <Divider my="6" borderColor="gray.700" />
                </>
              );
            })}
          </VStack>
        </Box>
      </Button>
    </HStack>
  );
}
