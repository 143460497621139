export const pdfRows = [
  {
    label: '3',
    value: 3,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '9',
    value: 9,
  },
]
