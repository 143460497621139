import { Flex, Button, useToast, Box, Heading, Divider, VStack, SimpleGrid, HStack } from '@chakra-ui/react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Input } from '../../../components/Form/Input'
import { api } from '../../../services/api'
import { SubmitHandler, useForm, FieldError } from 'react-hook-form'
import { Select } from '../../../components/Form/Select'
import { states } from '../../../utils/states'
import InputMask from 'react-input-mask'
import { Client } from '../../../Interfaces/client'
import { Link } from 'react-router-dom'

const createClientsSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  cnpj: yup.string().required('CNPJ obrigatório'),
  phone: yup.string().required('Telefone obrigatório'),
  email: yup.string().required('E-mail obrigatório'),
  address: yup.object().shape({
    zip_code: yup.string().required('CEP obrigatório'),
    state: yup.string().required('Estado obrigatório'),
    city: yup.string().required('Cidade obrigatória'),
    neighborhood: yup.string().required('Bairro obrigatório'),
    complement: yup.string().required('Endereço obrigatório'),
  }),
  company_name: yup.string().required('Razão Social obrigatório').nullable(),
  resp_name: yup.string().required('Nome do responsavel obrigatório').nullable(),
  resp_role: yup.string().required('Cargo obrigatório').nullable(),
  resp_email: yup.string().required('E-mail do responsavel obrigatório').nullable(),
})

export default function ClientsCreateOrUpdate({ match }: any) {
  const history = useHistory()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { id } = match.params

  const isCreateMode = !id

  const createClient = useMutation(
    async (client: Client) => {
      const responseAddress = await api.post('addresses', {
        zip_code: client.address.zip_code,
        state: client.address.state,
        city: client.address.city,
        neighborhood: client.address.neighborhood,
        complement: client.address.complement,
      })
      if (responseAddress) {
        client.address_id = responseAddress.data.id
        const responseClient = await api.post('clients', {
          address_id: client.address_id,
          name: client.name,
          cnpj: client.cnpj,
          email: client.email,
          phone: client.phone,
          company_name: client.company_name,
          ie: client.ie,
          im: client.im,
          resp_name: client.resp_name,
          resp_role: client.resp_role,
          resp_email: client.resp_email,
        })
        if (responseClient) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const updateClient = useMutation(
    async (client: Client) => {
      const responseAddress = await api.put(`addresses/${client.address_id}`, {
        zip_code: client.address.zip_code,
        state: client.address.state,
        city: client.address.city,
        neighborhood: client.address.neighborhood,
        complement: client.address.complement,
      })
      if (responseAddress) {
        client.address_id = responseAddress.data.id
        const responseClient = await api.put(`clients/${id}`, {
          address_id: client.address_id,
          name: client.name,
          cnpj: client.cnpj,
          email: client.email,
          phone: client.phone,
          company_name: client.company_name,
          ie: client.ie,
          im: client.im,
          resp_name: client.resp_name,
          resp_role: client.resp_role,
          resp_email: client.resp_email,
        })
        if (responseClient) {
          toast({ description: 'Cadastrado com sucesso.' })
        }
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients')
      },
      onError: ({ response }) => {
        const errorList = response.data.errors

        const toastOptions = errorList.map((err) => ({ description: err.message, status: 'error' }))
        toastOptions.forEach((t) => {
          toast(t)
        })
      },
    },
  )

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(createClientsSchema),
  })

  useQuery(
    'client',
    async () => {
      const { data } = await api.get(`clients/${id}`)
      reset(data)
    },
    {
      staleTime: 5000,
    },
  )

  const errors = formState.errors

  const handleCreateClient: SubmitHandler<Client> = async (values) => {
    isCreateMode ? await createClient.mutateAsync(values) : await updateClient.mutateAsync(values)

    history.push('/clients')
  }

  return (
    <Box flex="1" borderRadius="8" bg="gray.800" p="8" as="form" onSubmit={handleSubmit(handleCreateClient)}>
      <Heading size="lg" fontWeight="normal">
        {isCreateMode ? 'Novo Cliente' : 'Editar Cliente'}
      </Heading>
      <Divider my="6" borderColor="gray.700" />

      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Nome" {...register('name')} error={errors.name as FieldError} />
          <Input
            label="CNPJ"
            {...register('cnpj')}
            error={errors.cnpj as FieldError}
            as={InputMask}
            mask="99.999.999/9999-99"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input label="Telefone" {...register('phone')} error={errors.phone as FieldError} />
          <Input label="E-mail" {...register('email')} error={errors.email as FieldError} />
          <Input label="Razão Social" {...register('company_name')} error={errors.company_name as FieldError} />
          <Input label="Inscrição estadual" {...register('ie')} error={errors.ie as FieldError} />
          <Input label="Inscrição municipal" {...register('im')} error={errors.im as FieldError} />
        </SimpleGrid>
        <Heading size="md" fontWeight="normal">
          Endereço
        </Heading>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="CEP"
            {...register('address.zip_code')}
            error={(errors.address as { zip_code?: FieldError })?.zip_code || undefined}
            as={InputMask}
            mask="99999-999"
          />
          <Input
            label="Cidade"
            {...register('address.city')}
            error={(errors.address as { city?: FieldError })?.city || undefined}
          />
          <Select label="Estado" {...register('address.state')} options={states} placeholder="Estado" />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing="8" width="100%">
          <Input
            label="Endereço"
            {...register('address.complement')}
            error={(errors.address as { complement?: FieldError })?.complement || undefined}
          />
          <Input
            label="Bairro"
            {...register('address.neighborhood')}
            error={(errors.address as { neighborhood?: FieldError })?.neighborhood || undefined}
          />
        </SimpleGrid>
        <Heading size="md" fontWeight="normal">
          Responsavel
        </Heading>
        <Input label="Nome do responsavel: " {...register('resp_name')} error={errors.resp_name as FieldError} />
        <Input label="Cargo: " {...register('resp_role')} error={errors.resp_role as FieldError} />
        <Input label="Email: " {...register('resp_email')} error={errors.resp_email as FieldError} />
      </VStack>

      <Flex mt="8" justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to={`/clients`} colorScheme="whiteAlpha">
            Cancelar
          </Button>
          <Button type="submit" colorScheme="pink" isLoading={formState.isSubmitting}>
            Salvar
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
