import { Category } from '../Enuns/category.enum'

const normalizeRawMaterial = (params) => {
  const response = params

  const categoryOptions = Object.entries(Category).map((cat) => ({
    label: cat[1].toUpperCase(),
    value: cat[0],
  }))

  Object.entries(params).forEach(([key, value]: [key: string, value: string]) => {
    const currentCategory = categoryOptions.find(
      (c) => c.label === value.toUpperCase() || c.value === value.toUpperCase(),
    )

    if (key === 'category')
      if (currentCategory) response[key] = currentCategory.value
      else delete response[key]
  })

  return response
}

export const conditionalRegex = {
  '/raw-materials': [/#categoria(?<category>[^#]*)/i, /#nome(?<name>[^#]*)/i],
  '/providers': [/#nome(?<name>[^#]*)/i, /#cnpj[^#]*(?<cnpj>\d{2}[\.]?\d{3}[\.]?\d{3}[\/]?\d{4}[-]?\d{2})/],
  '/clients': [
    /#nome(?<name>[^#]*)/i,
    /#email(?<email>.*@.*.com)[^#]*/i,
    /#cnpj[^#]*(?<cnpj>\d{2}[\.]?\d{3}[\.]?\d{3}[\/]?\d{4}[-]?\d{2})/,
  ],
  '/users': [/#nome(?<name>[^#]*)/i, /#email(?<email>.*@.*.com)[^#]*/i, /#usuario(?<username>[^#]*)/i],
}

export const defaultRegex = {
  '/raw-materials': [/(?<name>.*)/i],
  '/providers': [/(?<name>.*)/i],
  '/clients': [/(?<name>.*)/i],
  '/users': [/(?<name>.*)/i],
}

export const normalize = {
  '/raw-materials': normalizeRawMaterial,
  // '/clients': normalizeClient,
}
